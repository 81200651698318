import React from 'react';
import { graphql } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import dayjs from 'dayjs';
import parse from '../utils/parse';
import Layout from '../components/layout';
import SEO from '../components/seo';

import './event.scss';

const getGoogleMapLink = (
  query: string,
  placeId: string,
) => `https://www.google.com/maps/search/?api=1&query=${
  encodeURIComponent(query)}&query_place_id=${placeId}`;

const getEventDate = (startDate: string, endDate: string | null) => {
  const startDayJs = dayjs(startDate);
  if (endDate) {
    let startFormat = 'D';
    const endDayJs = dayjs(endDate);
    if (endDayJs.month !== startDayJs.month) {
      startFormat += ' MMM';
    }
    return `${startDayJs.format(startFormat)} - ${endDayJs.format('D MMM YYYY')}`;
  }

  return startDayJs.format('dddd D MMM YYYY [at] HH:mm');
};

const Post = ({ data }: any) => {
  const {
    post: {
      title, content, featuredImage,
      eventData: {
        startDate, endDate, eventLocationLabel,
        googleMapLoc,
      },
    },
  } = data;

  const { streetAddress, placeId } = googleMapLoc || {};

  const image = getImage(featuredImage?.node.localFile);

  return (
    <Layout scheme="dark" categories="Events">
      <SEO title={title} />
      <article className="article event">
        <div className="article-inner">
          <div className="article-wrapper">
            <h2 className="article-title article-title--event">{title}</h2>
          </div>
          <div className="event-details">
            <div className="event-details-image">
              <GatsbyImage image={image} alt={featuredImage?.node.altText || ''} />
            </div>
            <div className="article-content">
              <p className="event-date-and-location">
                {getEventDate(startDate, endDate)}
                {streetAddress ? ', ' : ''}
                {streetAddress ? (
                  <a
                    href={getGoogleMapLink(streetAddress, placeId)}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {eventLocationLabel || streetAddress}
                  </a>
                ) : ''}
              </p>
              {parse(content)}
            </div>
          </div>
        </div>
      </article>
    </Layout>
  );
};

export default Post;

export const postQuery = graphql`
query EventById(
  $id: String!
) {
  # selecting the current post by id
  post: wpEvent(id: { eq: $id }) {
    id
    content
    title

    eventData {
      startDate
      endDate
      eventLocationLabel
      googleMapLoc {
        streetAddress
        placeId
      }
    }

    featuredImage {
      node {
        altText
        localFile {
          childImageSharp {
            gatsbyImageData(
              width: 675
              placeholder: BLURRED
              formats: [AUTO, WEBP]
              aspectRatio: 1.5
              quality: 95
            )
          }
        }
      }
    }
  }
}
`;
